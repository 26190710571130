<template>
    <draggable class="grid-drag" :list="dragItems" group="my-group"  :itemKey="id" :animation="200" ghost-class="moving-card" filter=".action-button">
        <!-- Define the item slot for each draggable item -->
        <template v-slot:item="{ element }">
            <div class="drag-item" :key="element.id">
                <v-icon :name="element.logo" class="item-icons"></v-icon>
                {{ element.name }}
            </div>
        </template>
    </draggable>
</template>

<script>
import draggable from "vuedraggable";

import { OhVueIcon, addIcons } from 'oh-vue-icons';
import { IoCloseOutline, IoCloseCircleOutline, MdEmailOutlined, HiUser, HiUsers, RiMessage2Line, IoClose } from 'oh-vue-icons/icons';

addIcons(IoCloseOutline, IoCloseCircleOutline, MdEmailOutlined, HiUser, HiUsers, RiMessage2Line, IoClose);

export default {
    components: {
        draggable,
        "v-icon": OhVueIcon,
    },
    props: {
        dragItems: Array
    }
}
</script>

<style scoped>
.grid-drag {
    padding: 15px 12px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.drag-item {
    margin-top: 8px;
    padding: 4px 6px;
    font-size: 14px;
    color: #474646;
    cursor: pointer;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.item-icons {
    font-size: 4px;
    color: #474646;
    
}

.moving-card {
    opacity: 0.5; /* Equivalent to opacity-50 */
    background-color: #f7fafc; /* Closest to bg-gray-100, check exact color */
    border: 1px solid #3182ce; /* Closest to border-blue-500, check exact color */
}

</style>