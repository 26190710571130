<template>
    <div>
        <v-icon name="io-close-circle-outline" scale="1.3" class="close-icon" />
    </div>
</template>

<script>
import { OhVueIcon, addIcons } from 'oh-vue-icons';
import { IoCloseCircleOutline } from 'oh-vue-icons/icons';

addIcons(IoCloseCircleOutline);

export default {
    components: {
        "v-icon": OhVueIcon
    }
}
</script>

<style scoped>
.close-icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px;
    cursor: pointer;
    color: #7c7b7b;
}
</style>