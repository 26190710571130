<template>
  <draggable
    class="grid-drop"
    :list="droppedItems"
    group="my-group"
    :itemKey="id"
    :animation="200"
    @start="handleDragStart"
    @end="handleDragEnd"
  >
    <!-- Define the item slot for each draggable item -->

    <template v-slot:item="{ element }">
      <form>
        <div
          :class="{
            invalid:
              (element.type === 'email' && userEmailValidity === 'invalid') ||
              (element.type === 'email' && userEmailValidity === 'empty'),
          }"
        >
          <p
            class="error"
            v-if="element.type === 'email' && userEmailValidity === 'invalid'"
          >
            Invalid email
          </p>
          <p
            class="error"
            v-else-if="
              element.type === 'email' && userEmailValidity === 'empty'
            "
          >
            Email must be filled
          </p>
          <div class="input-wrapper">
            <input
              class="droped-item"
              :key="element.id"
              v-model="element.enteredValue"
              :placeholder="element.name"
              :type="element.type"
              :required="element.required"
              @blur="handleBlur(element)"
              @focus="clearErrors(element)"
            />
            <v-icon
              name="io-close"
              class="input-icon"
              @click="deleteItem(element)"
            ></v-icon>
          </div>
        </div>
      </form>
    </template>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import { OhVueIcon, addIcons } from "oh-vue-icons";
import {
  IoCloseOutline,
  IoCloseCircleOutline,
  MdEmailOutlined,
  HiUser,
  HiUsers,
  RiMessage2Line,
  IoClose,
} from "oh-vue-icons/icons";

addIcons(
  IoCloseOutline,
  IoCloseCircleOutline,
  MdEmailOutlined,
  HiUser,
  HiUsers,
  RiMessage2Line,
  IoClose
);

export default {
  components: {
    draggable,
    "v-icon": OhVueIcon,
  },
  props: {
    droppedItems: Array,
    userEmailValidity: String,
    recentlyDragged: Boolean,
  },
  methods: {
    handleBlur(element) {
      if (!this.recentlyDragged) {
        this.$emit("blur", element);
      }
    },
    deleteItem(element) {
      this.$emit("delete-item", element);
    },
    clearErrors(element) {
      this.$emit("focus", element);
    },
    handleDragStart() {
      this.$emit("update-dragged", true);
    },
    handleDragEnd() {
      this.$emit("update-dragged", false);
    },
  },
};
</script>

<style scoped>
.grid-drop {
  padding: 15px 12px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.droped-item {
  position: relative;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 15px;
  padding-right: 40px;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.input-wrapper {
  position: relative;
  display: block;
}

.invalid input {
  border-color: red;
}

.input-icon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 16px;
  width: 16px;
  padding: 10px;
  color: #c3c2c2;
  cursor: pointer;
}

.error {
  color: red;
  font-size: 12px;
  margin: 0;
}

.sortable-ghost {
  visibility: hidden;
}
</style>
