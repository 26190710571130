<template>
  <button class="subscribe-btn">{{ lable }}</button>
</template>

<script>
export default {
  props: {
    lable: {
      type: String,
      default: "Button"
    },
    color1: {
      type: String,
      default: '#ff9966'
    },
    color2: {
      type: String,
      default: '#ff5e62'
    }
  }
};
</script>

<style scoped>
.subscribe-btn {
  padding: 0.8rem 2rem;
  border-radius: 5rem;
  background: linear-gradient(90deg, v-bind(color1), v-bind(color2));
  color: #fff;
  font-size: 0.9rem;
  border: none;
  outline: none;
  cursor: pointer;
}
</style>