<template>
  <div class="modal-backdrop">
    <div class="modal">
      <div>
        <p>Create your own custom form to subscribe:</p>
        <close-modal-btn @click="close"></close-modal-btn>
      </div>

      <line-break></line-break>

      <div class="grid-container">
        <transition-group name="flip-transition">
          <draggable-items :dragItems="inputOptions"></draggable-items>
          <drop-zone
            :droppedItems="selectedOptions"
            :userEmailValidity="userEmailValidity"
            @delete-item="returnItemToDraggZone"
            @blur="validateEmail"
            @focus="deleteErrors"
            @update-dragged="updateDragged"
          >
          </drop-zone>
        </transition-group>
      </div>

      <line-break></line-break>

      <div class="button-container">
        <base-button
          class="submit-button"
          @click="submitUserSubscription"
          lable="Submit"
        ></base-button>
      </div>
    </div>
  </div>
</template>

<script>
import CloseModalBtn from "./CloseModalBtn.vue";
import LineBreak from "@/components/UI/LineBreak.vue";
import DraggableItems from "./DraggableItems.vue";
import DropZone from "./DropZone.vue";
import BaseButton from "@/components/UI/BaseButton.vue";

export default {
  components: {
    CloseModalBtn,
    LineBreak,
    DraggableItems,
    DropZone,
    BaseButton  
  },

  data() {
    return {
      inputOptions: [
        {
          id: 1,
          name: "Email" + "*",
          enteredValue: "",
          type: "email",
          logo: "md-email-outlined",
          required: true,
        },
        {
          id: 2,
          name: "First name",
          enteredValue: "",
          type: "text",
          logo: "hi-user",
          required: false,
        },
        {
          id: 3,
          name: "Second name",
          enteredValue: "",
          type: "text",
          logo: "hi-users",
          required: false,
        },
        {
          id: 4,
          name: "Message",
          enteredValue: "",
          type: "text",
          logo: "ri-message-2-line",
          required: false,
        },
      ],
      selectedOptions: [],
      userEmailValidity: "pending",
      inputTouched: false,
      recentlyDragged: false,
    };
  },

  methods: {
    close() {
      this.$emit("close");
    },
    updateDragged(value) {
      this.recentlyDragged = value;
    },
    submitUserSubscription() {
      if(this.userEmailValidity === "valid" ) {
      fetch(
        "https://lucid-forms-user-subs-details-default-rtdb.europe-west1.firebasedatabase.app/subscription.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(
            this.selectedOptions.map((item) => ({
              name: item.name,
              enteredValue: item.enteredValue,
            }))
          ),
        }
      );
    } else {
      alert('Enter email corectly!');
    }
    },
    returnItemToDraggZone(element) {
      // Remove item from droppedItems
      const index = this.selectedOptions.findIndex((d) => d.id === element.id);
      if (index > -1) {
        this.selectedOptions.splice(index, 1);
      }
      element.enteredValue = "";
      this.userEmailValidity = "pending";
      // Add item back to dragItems
      this.inputOptions.push(element);
    },
    validateEmail(element) {
      if (element.type === "email") {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (element.type === "email" && element.enteredValue === "") {
          this.userEmailValidity = "empty";
          this.error == true;
        } else if (!regex.test(element.enteredValue)) {
          this.userEmailValidity = "invalid";
          this.error == true;
        } else {
          this.userEmailValidity = "valid";
          this.error = false;
        }
      }
    },
    deleteErrors(element) {
      if (
        (element.type === "email" && this.userEmailValidity === "invalid") ||
        (element.type === "email" && this.userEmailValidity === "empty")
      ) {
        this.userEmailValidity = "pending";
      }
    },
  },
  watch: {
    selectedOptions: {
      handler(newVal) {
        // Revert elements back to their original state when dragged back
        newVal.forEach((item) => {
          if (item.state === "input") {
            item.state = "draggable";
          }
        });
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  padding-top: 15px;
  font-size: 26px;
  font-weight: bolder;
  color: #333;
  text-align: center;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  position: relative;
  width: 650px;
  height: 455px;
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background-color: #f4f4f4;
}

.flip-transition-move {
  transition: all 0.7s;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;
  height: 260px;
}

.button-container {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
}

.submit-button {
  width: 30%;
  font-size: 18px;
}
</style>
