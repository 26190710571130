<template>
  <div class="wrapper">
    <div class="image">
      <i class="fas fa-envelope"></i>
    </div>

    <h2>Coming soon</h2>
    <p>Our developers are working hard to deliver something cool :)</p>
    <br />
    <base-button @click="open" lable="Subscribe"></base-button>
  </div>
</template>

<script>
import BaseButton from './UI/BaseButton.vue';
export default {
  components: {
    BaseButton
  },
  props: ['welcome_message'],
  methods: {
    open() {
      this.$emit('open');
    }
  }
}
</script>

<style scoped>
.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  height: 300px;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.763);
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 70px;
  text-align: center;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.fas.fa-envelope {
  color: #fff;
  font-size: 2rem;
  background: #333;
  padding: 1rem;
  border-radius: 100%;
  margin: 2rem 0 0.5rem 0;
}

h2 {
  font-size: 40px;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.926);
  margin: 0 0 0.3rem 0;
}

p {
  font-size: 20px;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.926);
  margin: 0 0 0.8rem 0;
}
</style>