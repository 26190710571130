<template>
    <div class="line-break"></div>
</template>

<style scoped>
.line-break {
    height: 2px;
    background-color: #e0e0e0;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>