<template>
  <div class="header">
    <h1>LucidForms</h1>
  </div>
</template>

<style scoped>
h1 {
  font-size: 30px;
  color: rgb(248, 240, 240);
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.header {
  text-align: left;
  padding-left: 20px;
  position: absolute;
  top: 0;
  left: 0;
}
</style>